import React, { useEffect, useState } from 'react';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';

import {
  CookieBody,
  CookieCheckbox,
  CookieFooter,
  CookieHeader,
  CookieHeaderWrapper,
  CookieParagraph,
  CookieText,
  CookieTypeWrapper,
  CookieWrapper,
  InputLabel,
  InputWrapper
} from './styled.components';
import { StyledBtn } from '../../../global/buttons/styled.components';

const CookieTypes = [
  {
    name: 'necessary',
    description: 'extras.necessary'
  },
  {
    name: 'preferences',
    description: 'extras.preferences'
  },
  {
    name: 'statistics',
    description: 'extras.statistics'
  }
];

const CookieBar = () => {
  const { t } = useTranslation();
  const [editCookies, setEditCookies] = useState(false);
  const [shouldShow, setShouldShow] = useState(false);

  const isRodoAccepted = () => {
    if (typeof document !== undefined) {
      const cookies = document.cookie.split(`; `);
      for (let cookie of cookies) {
        const item = cookie.split(`=`);
        const storedKey = item[0];
        const storedValue = item[1];
        if ('rodo_accepted' === storedKey) return storedValue;
      }
    }
    return false;
  };

  useEffect(() => {
    if (!isRodoAccepted()) setTimeout(() => setShouldShow(true), 700);
  }, []);

  const acceptAll = () => {
    document.cookie = 'rodo_accepted=true;max-age=31536000;path=/';
    document.cookie = 'optIn=all;max-age=31536000;path=/';
    setShouldShow(false);
  };

  const acceptSome = () => {
    document.cookie = 'rodo_accepted=true;max-age=31536000;path=/';
    const analiticsCheckbox = document.getElementById('statistics');
    const preferencesCheckbox = document.getElementById('preferences');
    if (analiticsCheckbox.checked) {
      document.cookie = 'optIn=all;max-age=31536000;path=/';
      setShouldShow(false);
      return;
    } else if (preferencesCheckbox.checked) {
      document.cookie = 'optIn=strict;max-age=31536000;path=/';
      setShouldShow(false);
      return;
    }
    document.cookie = 'optIn=strict;max-age=31536000;path=/';
    setShouldShow(false);
  };

  const declineAll = () => {
    document.cookie = 'rodo_accepted=false;max-age=31536000;path=/';
    document.cookie = 'optIn=none;max-age=31536000;path=/';
    setShouldShow(false);
  };

  if (!shouldShow) return null;

  return (
    <CookieWrapper>
      <CookieHeaderWrapper>
        <CookieHeader>{t`extras.cookie-title`}</CookieHeader>
        <Trans
          i18nKey={`extras.we-are-using-cookies`}
          parent={CookieText}
          components={{ polityka: <Link to="/rodo" /> }}
        />
      </CookieHeaderWrapper>
      {editCookies ? (
        <CookieBody>
          {CookieTypes.map((type, index) => {
            return (
              <CookieTypeWrapper key={index}>
                <InputWrapper>
                  <CookieCheckbox
                    checked={type.name === 'necessary' ? true : null}
                    name={type.name}
                    id={type.name}
                    type="checkbox"
                  />
                  <InputLabel htmlFor={type.name}>
                    {t(String('extras.' + type.name))}
                  </InputLabel>
                </InputWrapper>
                <CookieParagraph>
                  {t(String('extras.' + type.name + '-description'))}
                </CookieParagraph>
              </CookieTypeWrapper>
            );
          })}
        </CookieBody>
      ) : null}
      <CookieFooter>
        <StyledBtn className="hollow cookie" onClick={() => declineAll()}>
          {t('extras.decline')}
        </StyledBtn>
        <StyledBtn
          className="cookie"
          onClick={
            !editCookies ? () => setEditCookies(true) : () => acceptSome()
          }>
          {editCookies ? t('extras.save') : t('extras.edit-cookies')}
        </StyledBtn>
        <StyledBtn
          id="accept-all"
          className="solid cookie"
          onClick={() => acceptAll()}>
          {t('extras.accept-all')}
        </StyledBtn>
      </CookieFooter>
    </CookieWrapper>
  );
};

export default CookieBar;
