import styled from 'styled-components';
import { Link } from 'gatsby';

export const StyledHref = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  padding: 1rem 1.5rem;
  width: 200px;
  height: 1.4375rem;
  border: 2px solid #0f5575;
  cursor: pointer;
  color: #0f5575;
  text-decoration: none;
  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 1rem;
  > span {
    margin-right: 8px !important;
  }
  &:hover {
    background: #0f5575;
    color: white;
  }
  &.solid {
    border: 2px solid #0f5575;
    background-color: #0f5575;
    color: white;
    &:hover {
      background: transparent;
      color: #0f5575;
    }
  }
  &.hollow {
    border-color: transparent;
    background-color: transparent;
    color: #0f5575;
    &:hover {
      background: #0f5575;
      color: white;
    }
  }
  &.outlined {
    background: #0f5575;
    border: 2px solid white;
    color: white;
    &:hover {
      background: white;
      border: 2px solid #0f5575;
      color: #0f5575;
    }
  }
    &.white {
    background: white;
    text-align: center;
    &.wide {
    width: 300px;
    }
    border: 2px solid #0f5575;
    color: #0f5575;
    &:hover {
      background: transparent;
      border: 2px solid white;
      color: white;
    }
  }
`;


export const StyledButton = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  padding: 1rem 1.5rem;
  width: 200px;
  height: 1.4375rem;
  border: 2px solid #0f5575;
  cursor: pointer;
  color: #0f5575;
  text-decoration: none;
  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 1rem;
  > span {
    margin-right: 8px !important;
  }
  &:hover {
    background: #0f5575;
    color: white;
  }
  &.solid {
    border: 2px solid #0f5575;
    background-color: #0f5575;
    color: white;
    &:hover {
      background: transparent;
      color: #0f5575;
    }
  }
  &.hollow {
    border-color: transparent;
    background-color: transparent;
    color: #0f5575;
    &:hover {
      background: #0f5575;
      color: white;
    }
  }
  &.outlined {
    background: #0f5575;
    border: 2px solid white;
    color: white;
    &:hover {
      background: white;
      border: 2px solid #0f5575;
      color: #0f5575;
    }
  }
  &.white {
    background: white;
    border: 2px solid #0f5575;
    color: #0f5575;
    &:hover {
      background: transparent;
      border: 2px solid white;
      color: white;
    }
  }
`;

export const StyledBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  padding: 1rem 1.5rem;
  width: 200px;
  height: 1.4375rem;
  border: 2px solid #0f5575;
  cursor: pointer;
  color: #0f5575;
  background-color: transparent;
  text-decoration: none;
  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 1rem;
  > span {
    margin-right: 8px !important;
  }
  &:hover {
    background: #0f5575;
    color: white;
  }
  &.solid {
    border: 2px solid #0f5575;
    background-color: #0f5575;
    color: white;
    &:hover {
      background: transparent;
      color: #0f5575;
    }
  }
  &.hollow {
    border-color: transparent;
    background-color: transparent;
    color: #0f5575;
    &:hover {
      background: #0f5575;
      color: white;
    }
  }
  &.outlined {
    background: #0f5575;
    border: 2px solid white;
    color: white;
    &:hover {
      background: white;
      border: 2px solid #0f5575;
      color: #0f5575;
    }
  }
  &.cookie {
    width: fit-content;
  }
`;

