import styled, { keyframes } from "styled-components";
import { respondTo } from "../../../../utils/breakpoints";


export const scaleHeight = keyframes`
  from {
    max-height: 0;
  }
  to {
    max-height: 256px;
  }
`;

export const CookieWrapper = styled.div`
  position: fixed;
  max-height: 100%;
  ${respondTo.m`
    max-height: 532px;
    right: 12px;
    width: 606px;
  `}
  border-radius: 10px 10px 0px 0px;
  bottom: 0;
  background: white;
  z-index: 99;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
`

export const CookieHeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: between;
  gap: 4px;
  padding: 16px 24px;
  border-bottom: 1px solid #C4DFEC;
`

export const CookieHeader = styled.div`
  color: #0F5575;
  font-family: 'Montserrat', sans-serif;
  font-size: 18px;
  line-height: 27px;
  font-weight: 600;
  letter-spacing: -2%;
`

export const CookieText = styled.div`
  color: black;
  font-weight: 500;
  opacity: 0.6;
  font-size: 14px;
  line-height: 21px;
  font-family: 'Raleway', sans-serif;
`

export const CookieParagraph = styled.div`
  color: black;
  font-weight: 500;
  opacity: 0.6;
  font-size: 14px;
  line-height: 21px;
  font-family: 'Raleway', sans-serif;
`

export const CookieTypeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

export const CookieFooter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
  & > button {
      width: 100% !important;
      margin: 4px 0px;
  }
  box-sizing: border-box;
  padding: 16px 24px;
  border-top: 1px solid #C4DFEC;
  ${respondTo.m`
    max-height: 76px;
  gap: 8px;
  & > button {
    width: fit-content !important;
  }
  flex-direction: row;
  justify-content: flex-end;

  `}
`

export const CookieBody = styled.div`
  overflow-y: scroll;
  max-height: 226px;
  padding: 16px 24px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  animation: ${scaleHeight} 0.3s ease-in-out;
`

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
`

export const InputLabel = styled.label`
  color: black;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px !important;
  font-weight: 600;
  opacity: 0.87;
`

export const CookieCheckbox = styled.input`
  width: 18px;
  height: 18px;
`