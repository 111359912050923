import React from 'react';
import { Helmet } from 'react-helmet';
import { withPrefix } from "gatsby"

const CustomHead = () => (
  <Helmet htmlAttributes={{
    lang: 'pl',
  }}>
    <title>MedBiz</title>
    <script src={withPrefix('webp-format.js')} type="text/javascript" />
    <link rel="canonical" href="https://www.innovationshub.pl/" />
  </Helmet>
);

export default CustomHead;
