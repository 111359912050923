import * as React from 'react';
import useHotjar from 'react-use-hotjar';

import CustomHead from './components/custom-head';
import Footer from './components/footer';
import Menu from '../menu/menu';
import DonateModal from '../modals/DonateModal';
import { ModalContext } from '../../contexts/ModalContext';

import '../../styles/variables.css';
import '../../styles/global.css';
import CookieBar from './components/cookie-bar';
import { useEffect, useCallback } from 'react';

const Layout = ({
  children,
  className,
  withHeader = true,
  darkTheme = false
}) => {
  const urlParams = new URLSearchParams(
    typeof window !== `undefined` ? window.location.search : ''
  );
  const [isDonateModalOpen, setIsDonateModalOpen] = React.useState(false);
  const [optIn, setOptIn] = React.useState("");
  const [isClicked, setIsClicked] = React.useState(false);
  const paymentRedirect = urlParams.get('paymentRedirect');
  const { initHotjar } = useHotjar();

  const acceptCookies = () => {
    setIsClicked(true)
  }

  function gtag() {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(arguments);
  }

  const sendConsent = useCallback((consent) => {
    gtag('consent', 'update', consent);
  }, []);

  React.useLayoutEffect(() => {
    setTimeout(() => {
      let acceptAllButton = document.getElementById('accept-all');
      if (acceptAllButton) {
        acceptAllButton.addEventListener('click', acceptCookies);
      }
    }, 1000);
  }, [setIsClicked]);

  const checkOptIn = () => {
    if (typeof document !== 'undefined') {
      const cookies = document.cookie.split('; ');
      for (let cookie of cookies) {
        const item = cookie.split('=');
        if (item[0] === 'optIn') {
          setOptIn(item[1]);
        }
      }
    }
  };

  useEffect(() => {
    const head = document.querySelector('head');
    const analyticsTag = document.createElement('script');
    const gTagManager = document.createElement('script');
    head.appendChild(analyticsTag);
    head.appendChild(gTagManager);
    gTagManager.id = 'googleTag';
    analyticsTag.id = 'googleAnalytics';
    gTagManager.async = true;
    analyticsTag.async = true;
    analyticsTag.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag() { dataLayer.push(arguments); }
      gtag('consent', 'default', {
        'ad_user_data': 'denied',
        'ad_personalization': 'denied',
        'ad_storage': 'denied',
        'analytics_storage': 'denied',
        'wait_for_update': 500,
      });
      gtag('js', new Date());  
      gtag('config', 'G-KRYZ86QC05');
    `;
    gTagManager.innerHTML = `
    window.dataLayer = window.dataLayer || [];
    function gtag() { dataLayer.push(arguments); }
    gtag('consent', 'default', {
      'ad_user_data': 'denied',
      'ad_personalization': 'denied',
      'ad_storage': 'denied',
      'analytics_storage': 'denied',
      'wait_for_update': 500,
    });
    gtag('js', new Date());  
    gtag('config', 'GTM-TGLH3F2');
  `;
  }, []);

  useEffect(() => {
    checkOptIn();
  }, [isClicked]);

  useEffect(() => {
    if (optIn === 'strict') return;
    if (optIn === 'all') {
      initHotjar(3852238, 6, false);
      const consent = {
        'ad_storage': 'granted',
        'analytics_storage': 'granted',
        'ad_user_data': 'granted',
        'ad_personalization': 'granted',
      }
      sendConsent(consent)
      const firstScript = document.getElementById('googleAnalytics');
      const secondScript = document.getElementById('googleTag');
      const g4ascript = document.createElement('script');
      const gtagScript = document.createElement('script');
      g4ascript.async = true;
      gtagScript.async = true
      g4ascript.src = 'https://www.googletagmanager.com/gtag/js?id=G-KRYZ86QC05';
      gtagScript.src = 'https://www.googletagmanager.com/gtag/js?id=GTM-TGLH3F2';
      firstScript.parentNode.insertBefore(g4ascript, firstScript);
      secondScript.parentNode.insertBefore(gtagScript, secondScript);

    }
  }, [optIn, initHotjar, isClicked]);

  useEffect(() => {
    if (paymentRedirect) {
      setIsDonateModalOpen(true);
    }
  }, [paymentRedirect]);

  return (
    <ModalContext.Provider value={{ isDonateModalOpen, setIsDonateModalOpen }}>
      <div className={className}>
        <CustomHead />
        <Menu headerImg={withHeader} darkTheme={darkTheme} />
        <main>{children}</main>
        <DonateModal paymentRedirect={paymentRedirect} />
        <Footer darkTheme={darkTheme} />
        <CookieBar />
      </div>
    </ModalContext.Provider>
  );
};

export default Layout;
